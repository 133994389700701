<template>
    <div class="col-span-8">
        <gmap-map
            ref="maps"
            :center="{ lat:Number(marker.map_lat), lng:Number(marker.map_lng) }"
            :zoom="14"
            map-type-id="terrain"
            style="height: 400px; width:100%"
            :options="{
                scrollwheel: false,
            }"
        >
            <!-- <gmap-marker
                        :key="10"
                        :position="{ lat:Number(addressData.map_lat), lng:Number(addressData.map_lng)}"
                        :clickable="false"
                        :draggable="true"
                        @dragend="markerDragStart"
                ></gmap-marker> -->
        </gmap-map>
    </div>
</template>
<script>
export default {
    data() {
        return {
            marker: {
                map_lat: 59.3260664,
                map_lng: 17.8471233,
            },
        };
    },
};
</script>
